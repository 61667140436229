
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import * as React from 'react';
//import classes from './Custom404.module.scss'
//import {makeStyles} from "@material-ui/core/styles";
import {Inbox, Search} from "react-feather";
import {Box, Button, Typography} from "@material-ui/core"
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import {ButtonPrimary} from "../src/common_components";
import {useState} from "react";
import {useRouter} from "next/router";
//const useStyles = makeStyles(theme => ({

//}));

interface Custom404Props {

}

const Custom404 = (props: Custom404Props) => {
// const classes = useStyles();
    const {t} = useTranslation('common');
    const [loading, setLoading] = useState(false);
    const router = useRouter();

    return (
<div>
    <Box minHeight={'100vh'}
         flexDirection={'column'}
         display={'flex'}
         justifyContent={'center'}
         alignItems={'center'}>
        <Inbox style={{
            width: 64,
            opacity: .3,
            color: '#333333',
            height: 64
        }}/>
        <Box mt={2} mb={1}>
            <Typography variant={'h4'} align={'center'}>
                {t('404_title')}
            </Typography>
        </Box>
        <Box mb={4} maxWidth={400}>
            <Typography variant={'body1'} align={'center'}>
                {t('404_subtitle')}
            </Typography>
        </Box>
        <ButtonPrimary
            onClick={async () => {
                setLoading(true);
                await router.replace(`/`);
                setLoading(false);
            }}
            loading={loading}
            startIcon={<Search height={24} color={'#fff'}/>}
            color={'primary'} variant={'contained'}>
            {t('go_back_to_home')}
        </ButtonPrimary>
    </Box>

</div>    );
};

export default Custom404


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  